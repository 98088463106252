<template>
  <div>
    <el-row>
      <el-col>
        <head-layout
            :head-btn-options="headBtnOptions"
            :head-title="$t(`cip.plat.reportcenter.mediumScreen.title.indexHeadTitle`)"
            @head-remove="headRemove"
            @head-add-tabs="headAddTabs"
            @head-role-setting="headRoleSetting"
        ></head-layout>
      </el-col>
    </el-row>

    <grid-head-layout
        ref="gridHeadLayout"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
        ref="gridLayOut"
        :grid-row-btn="gridRowBtn"
        :table-options="tableOptions"
        :table-data="tableData"
        @gird-handle-select-click="selectionChange"
        :table-loading="tableLoading"
        :data-total="page.total"
        :page="page"
        @page-current-change="onLoad"
        @page-size-change="onLoad"
        @page-refresh-change="onLoad"
        @grid-row-detail-click="rowDetail"
        @row-remove="rowRemove"
        @row-edit="rowEdit"
    >
    </grid-layout>
    <CommonDialog v-if="settingVisible" width="30%" :dialogTitle="$t(`cip.plat.reportcenter.mediumScreen.title.roleNameTitle`)" @cancel="settingVisible = false" @confirm="handleSubmit">
      <el-tree :data="roleTreeData"
               show-checkbox node-key="id"
               ref="roleReportSetting"
               :default-checked-keys="roleReportObj"
               :props="props">
      </el-tree>
    </CommonDialog>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {mapGetters} from "vuex";
import {getList, remove, getRoleSetting, submitRoleSetting} from "@/api/system/mediumscreen";
import {getRolesTree} from "@/api/system/role";
import website from "@/config/website";
import CommonDialog from "@/components/CommonDialog";
export default {
  name: "mediumscreen",
  components: {
    GridLayout,
    HeadLayout,
    CommonDialog
  },
  data() {
    return {
      props: {
        label: "title",
        value: "id"
      },
      btnLoading: false,
      settingVisible: false,
      roleTreeData: [],
      roleReportObj: [],
      selectionList: [],
      tableLoading: true,
      tableData: [],
      dataTotal: null,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
    };
  },
  computed: {
    ...mapGetters(["permission", "userInfo"]),
    searchColumns() {
      return [
        {
          prop: "name",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t("cip.plat.reportcenter.mediumScreen.field.name"),
        },
        // {
        //   prop: "paramType",
        //   type: 'select',
        //   dataType: 'string',
        //
        //   dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=data_type",
        //   props: {
        //     label: "dictValue",
        //     value: "dictKey",
        //   },
        //   placeholder: this.$t(`sinoma.md.params.paramName`)
        // },
      ]
    },
    tableOptions() {
      return {
        height: 0,
        customAddBtn: true,
        linklabel: "urlPath",
        column: [
          //编码code
          //   {
          //     label: this.$t('sinoma.md.params.paramCode'),
          //     prop: 'paramCode',
          //     overHidden: true,
          //   },
          {
            label: this.$t('cip.plat.reportcenter.mediumScreen.field.name'),
            prop: 'name',
            overHidden: true,
            width: 400,
            align:'left',
          },
          {
            label: this.$t('cip.plat.reportcenter.mediumScreen.field.code'),
            prop: 'code',
            overHidden: true,
            width: 400,
            align:'left',
          },
          {
            label: this.$t('cip.plat.reportcenter.mediumScreen.field.urlPath'),
            prop: 'urlPath',
            align: "center",
            search: true,
            overHidden: true
          },
        ],
      }
    },
    gridRowBtn() {
      let btn = []
      if (this.permission.mediumscreen_edit) {
        btn.push({
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "row-edit",
          type: "button",
          icon: ""
        })
      }
      if (this.permission.mediumscreen_delete) {
        btn.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "row-remove",
          type: "button",
          icon: ""
        })
      }
      return btn
    },
    headBtnOptions() {
      let btn = []
      if (this.permission.mediumscreen_add) {
        btn.push({
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          // btnOptType: 'add',
        })
      }
      if (this.permission.mediumscreen_delete) {
        btn.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-remove",
          type: "button",
          icon: "",
          // btnOptType: 'remove',
        })
      }
      if (this.userInfo.role_name.includes('administrator')) {
        btn.push({
          label: this.$t('cip.plat.reportcenter.mediumScreen.btn.settingsBtn'),
          emit: "head-role-setting",
          type: "button",
          icon: "",
          remark: 'admin',
        })
      }
      return btn
    },
  },
  mounted() {
    this.onLoad(this.page, {});
    if (this.userInfo.role_name.includes('administrator')) {
      this.getRoleTreeByUserType();
    }

  },
  methods: {
    handleSubmit() {
      this.btnLoading = true
      let roleList = ""
      if (this.$refs.roleReportSetting.getCheckedKeys().length > 0) {
        roleList = this.$refs.roleReportSetting.getCheckedKeys().join(",");
      }
      submitRoleSetting(this.$refs.gridLayOut.selectionList[0].id, roleList)
          .then(() => {
            this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
            this.settingVisible = false
            this.btnLoading = false
          })
    },
    getRoleTreeByUserType() {
      getRolesTree(website.tenantId).then(res => {
        this.roleTreeData = res.data.data;
      });
    },
    headRoleSetting() {
      this.roleReportObj = []
      if (this.$refs.gridLayOut.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      } else if (this.$refs.gridLayOut.selectionList.length > 1) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectOneWarning'));
        return;
      }
      getRoleSetting(this.$refs.gridLayOut.selectionList[0].id).then((res) => {
        this.settingVisible = true
        this.roleReportObj = res.data.data
        this.$refs.roleReportSetting.setCheckedKeys(this.roleReportObj)

      })

    },
    selectionChange(list) {
      this.selectionList = list;
    },
    headRemove() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
          .then(() => {
            let ids = this.selectionList.map(item => {
              return item.id
            }).join(',')
            return remove(ids);
          })
        .then(() => {
          // 刷新表格数据并重载
          this.data = [];
          this.parentId = 0;
          this.$refs.gridLayOut.selectionClear();
          // 表格数据重载
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    headAddTabs() {
      this.$router.push({
        path: `/system/mediumscreenmanage/add`,
        query: {
          type: 'add',
          pageType: 'add',
        }
      })
    },
    rowEdit(row) {
      this.$router.push({
        path: `/system/mediumscreenmanage/edit`,
        query: {
          id: row.id,
          type: 'edit',
          pageType: 'edit',
        }
      })
    },

    rowDetail(row) {
      this.$router.push({
        path: `/system/mediumscreenmanage/view`,
        query: {
          id: row.id,
          type: 'view',
          pageType: 'detail',
        }
      })
    },

    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },
    gridHeadSearch(query) {
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.onLoad(this.page, query)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    }
  }
};
</script>

<style scoped>

</style>
